import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>this is my react application</h1>;
    </div>
  );
}
export default App;
